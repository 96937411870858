.landing-content {
    height: 88%;
    align-items: flex-end;
    margin-right: 2rem;
    --font-family: 'DM Serif Display', serif;
    font-weight: 300;
}

.landing-link-content {
    flex-direction: row;
}

.landing-title {
    font-size: 3rem;
    --opacity: 0.8;
}

.landing-subtitle {
    font-size: 2rem;
    --opacity: 0.8;
}

@media (max-width: 960px) {
    .landing-content {
        align-items: center;
        margin: unset;
    }

    .landing-link-content {
        flex-direction: column;
    }
    
    .landing-title {
        font-size: 2.5rem;
    }

    .landing-subtitle {
        font-size: 1.5rem;
    }
}
