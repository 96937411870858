.bg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 112dvh;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

}

.bg-landing {
    height: 100%;
    background-size: cover;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 1s ease;
}

.bg-default {
}

.header {
    font-weight: 300;
    text-decoration: none;
    cursor: pointer;
}

.header-landing {
    color: var(--pink-200);
}
.header-landing:hover {
    --color: #ffffff;
}

.header-default {
    color: var(--pink-200);
}
.header-default:hover {
    color: var(--gray-100);
}

.link {
    font-weight: 300;
    text-decoration: none;
    cursor: pointer;
}

.link-landing {
    color: #ffffff;
}
.link-landing:hover {
    color: var(--pink-200);
}

.link-default {
    color: var(--gray-100);
}
.link-default:hover {
    color: var(--pink-200);
}

.p-menubar {
    background: unset;
    font-family: unset;
    color: unset;
    border: unset;
    border-radius: unset;
    padding: 0 2rem 0 2rem;
}

.p-focus div {
    background: unset;
}
.p-focus div:hover {
    background: rgba(255, 255, 255, 0.04);
}

.p-menubar-end {
    display: none;
    margin: unset;
}

@media (max-width: 960px) {
    .link-landing {
        color: var(--gray-100);
    }

    .link-landing:hover {
        color: var(--pink-200);
    }

    .p-menubar {
        padding: 0 1rem 0 1rem;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list  {
        padding: 0;
    }
    
    .p-menubar-start {
        display: none;
    }

    .p-menubar-end {
        display: unset;
    }

    .p-menubar-end .header-landing {
        opacity: 0;
    }
}