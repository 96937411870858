.transition-a {
    transition: 0.2s;
}

.transition-a:hover {
    transform: scale(1.02);
    opacity: 0.7;
}

.p-galleria-close {
    z-index: 1;
}

.p-progress-spinner-circle {
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color-pink 6s ease-in-out infinite;
}

@keyframes p-progress-spinner-color-pink {
    100%, 0% {
        stroke: var(--pink-200);
    }
}
